export function AllTemplates() {
    return JSON.parse(localStorage.getItem('templates') || '{}');
}

export function SaveSelectedTemplate(t) {
  const template = t || null;
  localStorage.setItem('template', JSON.stringify(template));
  return template;
}

export function GetSelectedTemplate() {
  return JSON.parse(localStorage.getItem('template') || null);
}

export function SaveTemplate(name, template) {
  const existingTemplates = AllTemplates();
  existingTemplates[name] = (existingTemplates[name]) ? Object.assign(existingTemplates[name],template) : template;
  localStorage.setItem('templates', JSON.stringify(existingTemplates));
  return existingTemplates;
}

export function DeleteTemplate(name) {
  const configs = AllTemplates();
  delete configs[name];
  localStorage.setItem('templates', JSON.stringify(configs));
  return configs;
}

export function GetTemplate(name) {
  const configs = AllTemplates();
  let data = configs[name];
  if (!data) return null;
  data.name = name;
  return data;
}

export function GetListItem(name) {
  const listItems = MapToListItems();
  return listItems.find(t => t.name === name);
}

export function MapToListItems(t) {
  const templates = t || AllTemplates();
  return Object.keys(templates).map((configName) => {
    return { name: configName, initials: configName.substring(0, 2).toUpperCase(), href: '#', widgets: templates[configName].widgets };   
  });
}