import React, { useContext } from 'react';
import ManageTemplates from './ManageTemplates';
import { AppContext } from '../context/AppContext';
export default function PinnedTemplates({ setOpen = null }) {
  const { showPinnedTemplateButtons } = useContext(AppContext);
  return (
    <>
      <ManageTemplates className={showPinnedTemplateButtons ? `` : `hidden`} setOpen={setOpen} asPinnedButtons={true} />
    </>
  );
}
