import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { AppContext } from '../context/AppContext'
import { Popover, PopoverButton, PopoverPanel, Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState, useContext } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { PlusIcon } from '@heroicons/react/20/solid'
import Overlay from './Overlay'
import ExclamationTriangleIcon from '@heroicons/react/20/solid/ExclamationTriangleIcon'
import { RiUnpinFill, RiPushpinFill } from "react-icons/ri";
import { MdDriveFileRenameOutline, MdContentCopy, MdDelete } from "react-icons/md";

const colors = [
    "bg-red-500",
    "bg-blue-500",
    "bg-green-500",
    "bg-yellow-500",
    "bg-slate-500",
    "bg-orange-500",
    "bg-lime-500",
    "bg-emerald-500",
    "bg-teal-500",
    "bg-cyan-500",
    "bg-sky-500",
  ];
  
  const hashStringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << colors.length) - hash);
    }
    return colors[Math.abs(hash) % colors.length];
  };
  

export default function SimpleCardList({ list = [], emptyListText = "No items.", addWindowTitle = 'Add Item', renameWindowTitle = 'Rename Item', revertWindowTitle = 'Unsaved Changes', onItemClick, onRename, onDelete, onDuplicate, itemSubtext = null, pinnedItemsLocalStorageKey = 'pinnedItems', revertConfirmText, isAddWindowOpen, isRevertWindowOpen, setRevertWindowOpen, setAddWindowOpen, openRevertDialogSave, openRevertDialogContinue, children, pinnedItems = [], setPinnedItems = null }) {
  // Helper function to handle pinning/unpinning
  const [isEditWindowOpen, setEditWindowOpen] = useState(false);
  const [activelyEditedItem, setActivelyEditedItem] = useState(null);
  const [newActivelyEditedItemName, setNewActivelyEditedItemName] = useState('');
  function sortList(list) {
    return list.sort((a, b) => {
        if (isPinned(a) && !isPinned(b)) return -1;
        if (!isPinned(a) && isPinned(b)) return 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;

        return 0;
    });
  };

  const onPin = (item) => {
    if (!isPinned(item)) {
        setPinnedItems(pinnedItems.concat([item]));
        localStorage.setItem(pinnedItemsLocalStorageKey, JSON.stringify(pinnedItems.concat([item])));
    }
  };
  const isPinned = (item) => {
    const matches = pinnedItems.find((i) => i.name === item.name);
    return !matches ? false : true;
  };
  const unPin = (item, callback = null) => {
    setPinnedItems(pinnedItems.filter((i) => i.name !== item.name));
    localStorage.setItem(pinnedItemsLocalStorageKey, JSON.stringify(pinnedItems.filter((i) => i.name !== item.name)));
    if (typeof callback === 'function') callback();
};
const updatePinnedItem = (oldItem, newItem) => {
    const newPinnedItems = pinnedItems.map((i) => {
        if (i.name === oldItem.name) {
            return newItem;
        }
        return i;
    });
    setPinnedItems(newPinnedItems);
    localStorage.setItem(pinnedItemsLocalStorageKey, JSON.stringify(newPinnedItems));
};
const handleAddWindowClose = () => {
    console.log('Closing add window');
    setNewActivelyEditedItemName('');
};
  const onDeleteProxy = (item) => {
    unPin(item, () => onDelete(item));
  }
  const openRenameDialog = (item) => {
    setActivelyEditedItem(item);
    setNewActivelyEditedItemName(item.name);
    setEditWindowOpen(true);
  }
  const onRenameProxy = () => {
    const newActivelyEditedItem = {
        ...activelyEditedItem,
        name: newActivelyEditedItemName
    };
    onRename(activelyEditedItem, newActivelyEditedItem);
    if (isPinned(activelyEditedItem)) { // Untested
        updatePinnedItem(activelyEditedItem, newActivelyEditedItem);
        //unPin(activelyEditedItem, () => onPin(newActivelyEditedItem));
    };
    setEditWindowOpen(false);
  }
  const renderItem = (item) => {
    if (!item) return;
    return <div className="col-span-1 flex rounded-md shadow-sm">
        <div
        onClick={() => onItemClick && onItemClick(item)}
        className={`flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white cursor-pointer ${hashStringToColor(item.name)}`}>
        {item.initials}
        </div>
        <div className="flex flex-1 items-center justify-between  rounded-r-md border-b border-r border-t border-gray-200 bg-white">
        <div className="flex-1 truncate px-4 py-2 text-sm cursor-pointer" onClick={() => onItemClick && onItemClick(item)}>
            <a href="#" className="font-medium text-gray-900 hover:text-gray-600">
            {item.name}
            </a>
            {!itemSubtext ? null : itemSubtext(item)}
        </div>
        <div className="flex-shrink-0 pr-2 relative">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                <Menu.Button className="z-10 inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <EllipsisVerticalIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                    />
                </Menu.Button>
                </div>
                <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                >
                <Menu.Items className="z-20 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                    <div className="px-1 py-1 ">
                    {setPinnedItems && onPin && !isPinned(item) && (
                        <Menu.Item>
                            {({ active }) => (
                            <button onClick={() => onPin(item)}
                                className={`${
                                active ? 'bg-blue-500 text-white' : 'text-gray-900'
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer`}
                            >
                                {active ? ( 
                                <RiPushpinFill
                                    className="mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                                ) : (
                                <RiPushpinFill
                                    className="mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                                )}
                                Pin
                            </button>
                            )}
                        </Menu.Item>
                    )}
                    {setPinnedItems && unPin && isPinned(item) && (
                        <Menu.Item>
                            {({ active }) => (
                            <button onClick={() => unPin(item)}
                                className={`${
                                active ? 'bg-blue-500 text-white' : 'text-gray-900'
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer`}
                            >
                                {active ? (
                                <RiUnpinFill
                                    className="mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                                ) : (
                                <RiUnpinFill
                                    className="mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                                )}
                                Un-Pin
                            </button>
                            )}
                        </Menu.Item>
                    )}
                    {onRename && (
                        <Menu.Item>
                            {({ active }) => (
                            <button onClick={() => openRenameDialog(item)}
                                className={`${
                                active ? 'bg-blue-500 text-white' : 'text-gray-900'
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer`}
                            >
                                {active ? (
                                <MdDriveFileRenameOutline
                                    className="mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                                ) : (
                                <MdDriveFileRenameOutline
                                    className="mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                                )}
                                Rename
                            </button>
                            )}
                        </Menu.Item>
                    )}
                    {onDuplicate && (
                        <Menu.Item>
                            {({ active }) => (
                            <button onClick={() => onDuplicate(item)}
                                className={`${
                                active ? 'bg-blue-500 text-white' : 'text-gray-900'
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer`}
                            >
                                {active ? (
                                <MdContentCopy 
                                    className="mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                                ) : (
                                <MdContentCopy 
                                    className="mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                                )}
                                Duplicate
                            </button>
                            )}
                        </Menu.Item>
                    )}
                    {onDelete && (
                        <Menu.Item>
                            {({ active }) => (
                            <button onClick={() => onDeleteProxy(item)}
                                className={`${
                                active ? 'bg-blue-500 text-white' : 'text-gray-900'
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer`}
                            >
                                {active ? (
                                <MdDelete 
                                    className="mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                                ) : (
                                <MdDelete 
                                    className="mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                                )}
                                Delete
                            </button>
                            )}
                        </Menu.Item>
                    )}
                    </div>
                </Menu.Items>
                </Transition>
            </Menu>
            </div>
        </div>
    </div>
}

  return (
    <>
    <div>
      {!pinnedItems.length ? null: <h2 className="text-sm font-medium text-gray-500">Pinned</h2>}
      {list.length ? null: <p onClick={() => setAddWindowOpen(true)} className="mx-3 cursor-pointer text-center text-xl text-gray-400">{emptyListText}</p>}
      <ul role="list" className="mt-3 grid grid-cols-1 gap-5">
        {sortList(list).map((item, itemIndex) => (
          <li key={item.name} className={itemIndex+1 == (pinnedItems?.length || 0) ? `pb-4 border-b-2` : ``}>
            {renderItem(item)}
          </li>
        ))}
      </ul>
      <div className="mt-5 flex justify-center">
        <button
            onClick={() => setAddWindowOpen(true)}
            type="button"
            className="rounded-full bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
            <PlusIcon className="h-8 w-8" aria-hidden="true" />
        </button>
      </div>
    </div>
    <Overlay open={isAddWindowOpen} setOpen={setAddWindowOpen} onClose={handleAddWindowClose} className="p-6" title={addWindowTitle}>
        {children}
    </Overlay>
    <Overlay open={isEditWindowOpen} setOpen={setEditWindowOpen} className="p-6" title={renameWindowTitle}>
        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
            New Name
        </label>
        <div className="mt-2">
            <input
            id="name"
            name="name"
            type="text"
            onChange={(e) => setNewActivelyEditedItemName(e.target.value)}
            value={newActivelyEditedItemName}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
            <button onClick={() => setEditWindowOpen(false)} type="button" className="text-sm font-semibold leading-6 text-gray-900">
            Cancel
            </button>
            <button
            onClick={onRenameProxy}
            type="submit"
            className="rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
            Update
            </button>
        </div>
    </Overlay>
    {!setRevertWindowOpen ? null : <Overlay open={isRevertWindowOpen} setOpen={setRevertWindowOpen} className="p-6" title={revertWindowTitle}>
        <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <div className="mt-2">
                <p className="text-sm text-gray-500">
                {revertConfirmText || "You have unsaved changes. Are you sure you want to continue?"}
                </p>
            </div>
            </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            {!openRevertDialogSave ? null : <button
            type="button"
            onClick={() => openRevertDialogSave()}
            className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 sm:ml-3 sm:w-auto"
            >
            Save Changes
            </button>}
            <button
            type="button"
            onClick={() => !openRevertDialogContinue ? setRevertWindowOpen(false) : openRevertDialogContinue()}
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            >
            Continue Without Saving
            </button>
        </div>
    </Overlay>}
    </>
  )
}
