import React, { useState, useContext, useEffect } from 'react';
import { GiFlyingFlag } from "react-icons/gi";
import { ImSpinner2 } from "react-icons/im";
import { AppContext } from '../../context/AppContext';
import Overlay from '../Overlay';
import StringHelper  from '../../helpers/string';
import { FormatDataPoint } from './index';

function calculateRiderGap(lapSummary, r1, r2) {
  const racerIndex = lapSummary.findIndex(r => r.name === r1.name);
  const racerLap = lapSummary[racerIndex];
  const otherRacerIndex = lapSummary.findIndex(r => r.name === r2.name);
  const otherRacerLap = lapSummary[otherRacerIndex];

  // TODO: add up sectors times similar to calc commented below but offset sector both sector times by their diff property subtract aggregate r1 sector time from r2 sector time
  const currentSector = parseInt(racerLap.current_sector);

  let s1Diff = 0;
  if (currentSector >= 1) {
    s1Diff = parseFloat(otherRacerLap.s1) - parseFloat(racerLap.s1)
  }
  let s2Diff = 0;
  if (currentSector >= 2) {
    s2Diff = parseFloat(otherRacerLap.s2) - parseFloat(racerLap.s2)
  }
  let s3Diff = 0;
  if (currentSector >= 3) {
    s3Diff = parseFloat(otherRacerLap.s3) - parseFloat(racerLap.s3)
  }
  let s4Diff = 0;
  if (currentSector >= 4) {
    s4Diff = parseFloat(otherRacerLap.s4) - parseFloat(racerLap.s4)
  }
  let s5Diff = 0;
  if (currentSector >= 5) {
    s5Diff = parseFloat(otherRacerLap.s5) - parseFloat(racerLap.s5)
  }

  let diff = 0;
  // Loop over lapSummary looking for r1 or r2 and calculate the diff by adding up the gap property for each rider between r1 and r2. If r2 is found first then negate the gap property.
  for (let i = 0; i < lapSummary.length; i++) {
    if (lapSummary[i].name === r1.name || lapSummary[i].name === r2.name) {
      if (lapSummary[i].name === r1.name) {
        diff += parseFloat(lapSummary[i].diff);
      } else {
        diff -= parseFloat(lapSummary[i].diff);
      }
    }
  }

  const diffs = [diff, s1Diff, s2Diff, s3Diff, s4Diff, s5Diff];
  //console.log(racerLap.name, otherRacerLap.name, diffs);
  const gap = diffs.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return { name: r2.name, gap, diffs};
}

function calculateRiderGaps(lapSummary) {
  if (!lapSummary) return null;
  const gaps = lapSummary.map((r1) => {
    let r2Gaps = lapSummary.map((r2) => {
      return calculateRiderGap(lapSummary, r1, r2);
    });
    return {name: r1.name, gaps: r2Gaps.sort((a, b) => {
      if (a.gap === 0 && b.gap === 0) return 0; // Both are 0, maintain current order
      if (a.gap === 0) return 1; // a is 0, sort a to the back
      if (b.gap === 0) return -1; // b is 0, sort b to the back
      return a.gap - b.gap;
    })};
  });
  return gaps;
}

function getRidersWithinOneSecondDirectional(riderGaps, lapSummary, racerIndex, up = false) {
  const selectedRiderGaps = riderGaps.find(r => r.name === lapSummary[racerIndex].name);
  if (!selectedRiderGaps) return null;

  const direction = up ? -1 : 1;
  let index = racerIndex;
  const result = [];//(up && lastRiderGap.gap > 1) || (!up && lastRiderGap.gap > 1) && 
  const compare = (gap1, gap2) => !up ?  (!result.length && gap2 - gap1 <= -1) || (result.length > 0 && gap2 - gap1 > -1) : (!result.length && gap2 - gap1 >= 1) || (result.length > 0 && gap2 - gap1 < 1);

  while (index >= 0 && index < lapSummary.length) {
    const currentRiderGap = selectedRiderGaps.gaps.find(r => r.name === lapSummary[index].name);
    if (racerIndex != index) {
      const lastRiderGaps = !result.length ? selectedRiderGaps : riderGaps.find(r => r.name === lapSummary[!up ? index-1 : index+1]?.name);
      if (!lastRiderGaps) break;
      if (!result.length) {
        result.push(currentRiderGap);
      } else {
        const lastRiderGap = result[result.length-1];//lastRiderGaps.gaps.find(r => r.name === currentRiderGap.name);//(up && lapSummary.length >= index+1) || (!up && 0 <= index-1) ? currentRiderGap.gaps.find(r => r.name === lapSummary[index].name) : null;
        //if (up) console.log(currentRiderGap.name, lastRiderGap.name, currentRiderGap.gap - lastRiderGap.gap ,(!result.length && currentRiderGap.gap - lastRiderGap.gap <= -1), lastRiderGap.name, compare(lastRiderGap.gap, currentRiderGap.gap));
        if (compare(lastRiderGap.gap, currentRiderGap.gap)) {
          result.push(currentRiderGap);
        } else {
          break;
        }
      }
    }
    index += direction;
  }

  return result;
}

function Gap({ id, settings = {}, styleId = null, racer }) {
  const { laps, lapSummary } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [riderGaps, setRiderGaps] = useState(() => calculateRiderGaps(lapSummary, racer));
  const [racerIndex, setRacerIndex] = useState(() => lapSummary.findIndex(r => r.name === racer?.name));
  const [selectedRacerGaps, setSelectedRacerGaps] = useState(null);
  const [aheadRacerGaps, setAheadRacerGaps] = useState(null);
  const [behindRacerGaps, setBehindRacerGaps] = useState(null);
  const [aheadGroup, setAheadGroup] = useState(null);
  const [behindGroup, setBehindGroup] = useState(null);
  
  useEffect(() => {
    setRiderGaps(() => calculateRiderGaps(lapSummary, racer));
    setRacerIndex(() => lapSummary.findIndex(r => r.name === racer?.name));
  }, [lapSummary, racer]);

  useEffect(() => {
    setSelectedRacerGaps(() => riderGaps.find(r => r.name === racer.name));
  }, [riderGaps, racer]);

  useEffect(() => {
    setAheadGroup(() => getRidersWithinOneSecondDirectional(riderGaps, lapSummary, racerIndex, true));
  }, [aheadRacerGaps]);
  
  useEffect(() => {
    setBehindGroup(() => getRidersWithinOneSecondDirectional(riderGaps, lapSummary, racerIndex));
  }, [behindRacerGaps]);

  useEffect(() => {
    if (selectedRacerGaps?.gaps) {
      //setAheadRacerGaps(() => racerIndex == selectedRacerGaps.gaps.length+1 ? null : selectedRacerGaps.gaps.find(r => r.name === selectedRacerGaps.gaps[racerIndex-1]?.name));
      //setBehindRacerGaps(() => racerIndex == selectedRacerGaps.gaps.length-1 ? null : selectedRacerGaps.gaps.find(r => r.name === selectedRacerGaps.gaps[racerIndex+1]?.name));
      setAheadRacerGaps(() => racerIndex-1 <= 0 ? null : selectedRacerGaps.gaps.find(r => r.name === lapSummary[racerIndex-1]?.name));
      setBehindRacerGaps(() => racerIndex+1 >= lapSummary.length ? null : selectedRacerGaps.gaps.find(r => r.name === lapSummary[racerIndex+1]?.name));
    }
  }, [selectedRacerGaps]);

  //console.log(aheadGroup);
  const dataUnavailable = !selectedRacerGaps;//  || !aheadRacerGaps;
  return (
    (!lapSummary || !racer ) ? <ImSpinner2 className="animate-spin text-5xl" /> :
      <>
      <div className="flex flex-col w-full h-full cursor-pointer" onClick={() => setIsOpen(true)}>
        <div className="text-base">{StringHelper.abbreviateName(racer?.name)}</div>
        <div className="text-center">{dataUnavailable ? <ImSpinner2 className="animate-spin text-2xl w-full text-center" /> : `+${selectedRacerGaps.gaps.find(r => r.name === selectedRacerGaps.name).gap.toFixed(3)}` || '-'}{(aheadGroup?.length-1 > 1) ? ` G${aheadGroup?.length-1}` : ''}</div>
        <div className="text-center text-base">{dataUnavailable ? <ImSpinner2 className="animate-spin text-2xl w-full text-center" /> : StringHelper.abbreviateName(!lapSummary || lapSummary.length == 1 ? null : lapSummary[racerIndex-1]?.name) || '-'}</div>
        {parseFloat(selectedRacerGaps?.gaps.find(r => r.name === selectedRacerGaps.name).gap)>=0 ? null : <div className="text-center text-base">Potential Overtake</div>}
        <hr className="bg-grey-500 opacity-40 my-4"></hr>
        <div className="text-center text-base">{dataUnavailable ? <ImSpinner2 className="animate-spin text-2xl w-full text-center" /> : StringHelper.abbreviateName(lapSummary[racerIndex+1]?.name) || '-'}</div>
        <div className="text-center">{dataUnavailable ? <ImSpinner2 className="animate-spin text-2xl w-full text-center" /> : parseFloat(behindRacerGaps?.gap).toFixed(3) || '-'}{(behindGroup?.length > 1) ? ` G${behindGroup?.length}` : ''}</div>
        {parseFloat(behindRacerGaps?.gap)<=0 ? null : <div className="text-center text-base">Potential Overtake</div>}
      </div>
      {dataUnavailable ? null : 
        <Overlay open={isOpen} setOpen={setIsOpen} title={`Gap Overview`} className="p-6">
          {JSON.stringify(aheadGroup)}
          <div className="flex flex-col w-full">
            <div className="text-lg">Diffs to {racer.name}</div>
            <div className="flex flex-col">
              {selectedRacerGaps?.gaps.map((r) => {
                return r.name == racer.name ? null : (
                  <div className="flex flex-row justify-between">
                    <div className="text-base">{StringHelper.abbreviateName(r.name)}</div>
                    <div className="text-base">{r.gap}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </Overlay>
      }
      </>
  );
}

export default Gap;
