import { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import { ImSpinner2 } from "react-icons/im";
import { FormatDataPoint,  } from './index';
import StringHelper  from '../../helpers/string';
import Overlay from '../Overlay';
import Table from '../Table';
import { TabList, Tab } from '../Tabs';
import SelectInput from '../SelectInput';
import { SortLapSummary } from '../../helpers/LapSummary';

export default function Speed({ id, settings = {}, styleId = null, racer }) {
  const { lapSummary, laps, selectedRacer } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLapsRacer, setSelectedLapsRacer] = useState(() => racer?.name);
  const [maxSessionSpeed, setMaxSessionSpeed] = useState(null);
  const [maxSessionSpeedRiderName, setMaxSessionSpeedRiderName] = useState(null);

  let discardedSpeeds = [];
  function isSpeedDiscarded(value) {
    if (!value || !value.length) return true;
    try {
      let parsedValue = parseFloat(value);
      return discardedSpeeds.includes(parsedValue);
    } catch (e) {
      return true;
    }
  }
  function getSpeedDistanceFromMax(value) {
    if (!value) return null;
    try {
      let parsedValue = parseFloat(value);
      return parsedValue - maxSessionSpeed;
    } catch (e) {
      return null;
    }
  }

  useEffect(() => {
    if (racer) setSelectedLapsRacer(racer?.name);
  }, [isOpen]);

  useEffect(() => {
    setMaxSessionSpeed(lapSummary.reduce((acc, lap) => {
      const parsedSpeed = parseFloat(lap.max_speed);
      return Number.isNaN(parsedSpeed) ? acc : Math.max(acc, parsedSpeed);
    }, -Infinity));
    if (maxSessionSpeed > -Infinity) {
      setMaxSessionSpeedRiderName(StringHelper.abbreviateName(lapSummary.find(lap => parseFloat(lap.max_speed) === maxSessionSpeed)?.name));
    }
  }, [lapSummary]);

  // Clone some input vars so that they can be modifed before displaying
  let lapSummaryMod = structuredClone(lapSummary);
  let avgSessionSpeed = null;
  let sessionTotal = 0;
  let sessionLapCount = 0;

  // Loop over each racer in lapSummary
  lapSummaryMod.forEach(rider => {
    let total = 0;
    let count = 0;
    let max = null;

    // Get laps for this racer from a separate laps array where lap.name matches racer.name
    rider.laps = laps.filter(lap => lap.name === rider.name);

    if (rider.laps.length > 0) {
      max = rider.laps.reduce((acc, lap) => {
        const parsedSpeed = parseFloat(lap.speed);
        return Number.isNaN(parsedSpeed) ? acc : Math.max(acc, parsedSpeed);
      }, -Infinity);
    }
    
    // Loop over all filtered laps and get the min/max value for the racer
    if (max != null) {
      rider.laps.forEach(lap => {
        try {
          let value = parseFloat(lap.speed);

          if (maxSessionSpeed <= parseInt(settings.Discard_Speeds_Slower_Than) + value) {
            total += value;
            sessionTotal += value;
            count++;
            sessionLapCount++;
          } else {
            discardedSpeeds.push(value);
            //console.log(rider.name, 'Discarding', value, 'Max', maxSessionSpeed);
          }
        } catch (e) {
          // Do nothing
        }
      });
    }

    // Calculate an average if count is not zero and set to the data point accessor
    if (count > 0) {
      rider.speed = (total / count);
    } else {
      rider.speed = parseFloat(rider.speed);
    }
    if (sessionLapCount > 0) {
      avgSessionSpeed = (sessionTotal / sessionLapCount);
    }
  });

  let racerMod = lapSummaryMod.find(racer => racer.name === settings.Racer) || lapSummaryMod.find(racer => racer.name === selectedRacer);

  let highlightRowRules = [];
  let hideRowRules = [];
  let tableCols = [
    { Accessor: "position" },
    { Accessor: "name", Mutate: (value) => StringHelper.abbreviateName(value) },
    { Accessor: 'speed', Mutate: (value) => FormatDataPoint('Speed', value, true) }
  ];
  let lapHighlightRowRules = [];
  let lapTableCols = [
    { Accessor: "lapindex", Header: "Lap" },
    { Accessor: 'speed', Mutate: (value) => FormatDataPoint('Speed', value, true) },
    { Accessor: 'speed', Header: 'Mph Away Session Max', Mutate: (value) => `${isSpeedDiscarded(value) ? 'Discarded ' : ''} ${getSpeedDistanceFromMax(value) ? `${getSpeedDistanceFromMax(value)?.toFixed(2)} mph off pace` : ``}` }
  ];
  let racersSorted;
  if (racer && racerMod) {
    racersSorted = SortLapSummary(lapSummaryMod, 'speed', true);
    hideRowRules = [
      { Accessor: ['speed'], Value: racerMod.speed, Comparator: "<"},
      { Accessor: ['speed'], Value: 0, Comparator: "==", parseMethod: parseFloat}
    ];
    lapHighlightRowRules = [
      { Accessor: 'speed', Value: racer.max_speed }
    ];
    highlightRowRules = [
      { Accessor: "name", Value: racerMod.name }
    ];
  }

  let avgRacerSpeed = racerMod?.speed ? racerMod.speed : 0;
  let racerDiff = (avgRacerSpeed - avgSessionSpeed);
  return (
    (!racer) ? <ImSpinner2 className="animate-spin text-5xl" /> :
    <>
    <div className="cursor-pointer flex flex-col w-full h-full" onClick={() => setIsOpen(true)}>
      {
        (settings.Show_Session_Top_Speed == '0') ? '' :  
        <>
        <div className="text-base">Session Top Speed</div>
        <div className="text-base">{maxSessionSpeedRiderName}</div>
        <div>{maxSessionSpeed?.toFixed(2)} mph</div>
        <hr className={(settings.Show_Session_Average_Speed == '1' || settings.Show_Rider_Average_Speed_Diff == '1') ? `bg-grey-500 opacity-40 my-4` : `hidden`}></hr>
        </>
      }
      {
        (settings.Show_Session_Average_Speed == '0') ? '' :  
        <>
        <div className="text-base">Session Avg. Speed</div>
        <div>{avgSessionSpeed?.toFixed(2)} mph</div>
        <hr className={(settings.Show_Rider_Average_Speed_Diff == '1') ? `bg-grey-500 opacity-40 my-4` : `hidden`}></hr>
        </>
      }
      {
        (settings.Show_Rider_Average_Speed_Diff == '0') ? '' :  
        <>
        <div className="text-base">Avg. Speed Diff</div>
        <div className="text-base">{StringHelper.abbreviateName(racer.name)}</div>
        <div>{racerDiff?.toFixed(2)} mph</div>
        </>
      }
    </div>

    <Overlay open={isOpen} setOpen={setIsOpen} title={`${StringHelper.abbreviateName(racer.name)} Speed Overview`} className="p-6">
        <TabList navClassName="sticky top-0 py-4 pb-0 w-full bg-white" contentClassName="overflow-hidden p-6 pt-3">
          <Tab name="Summary">
            <div className='text-center my-4'>
              <div className="text-base">Session Max Speed</div>
              <div>{maxSessionSpeedRiderName}</div>
              <div className="text-4xl font-bold">{maxSessionSpeed?.toFixed(2)} mph</div>
            </div>
            <div className='text-center my-4'>
              <div className="text-base">Session Average Speed</div>
              <div className="text-4xl font-bold">{avgSessionSpeed?.toFixed(2)} mph</div>
            </div>
            <div className='text-center my-4'>
              <div className="text-base">Rider Average Speed</div>
              <div className="text-4xl font-bold">{avgRacerSpeed?.toFixed(2)} mph</div>
            </div>
            <div className='text-center my-4'>
              <div className="text-base">Rider Diff Session Average Speed</div>
              <div className="text-4xl font-bold">{racerDiff?.toFixed(2)} mph</div>
            </div>
            <div className='text-center my-4'>
              <div className="text-base">* Speeds Off Pace By <strong>{settings.Discard_Speeds_Slower_Than} mph</strong> Are Discarded.</div>
            </div>
          </Tab>
          <Tab name="Faster Average">
            <Table data={racersSorted} columns={tableCols} highlightRowRules={highlightRowRules} hideRowRules={hideRowRules} lightBg={true} />
          </Tab>
          <Tab name="All Riders Average">
            <Table data={racersSorted} columns={tableCols} highlightRowRules={highlightRowRules} lightBg={true} />
          </Tab>
          <Tab name="All Lap Speeds">
            <SelectInput
              label="Rider"
              options={lapSummary.map(value => ({ value: value.name }))}
              selected={selectedLapsRacer}
              onChange={(e) => setSelectedLapsRacer(e)}
            />
            <Table data={laps.filter(value => value.name === selectedLapsRacer)} columns={lapTableCols} highlightRowRules={lapHighlightRowRules} lightBg={true} />
          </Tab>
        </TabList>
      </Overlay>
      </>
  );
}
