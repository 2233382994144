import { MdFilter1, MdFilter2 } from "react-icons/md";
import { GiFlyingFlag } from "react-icons/gi";
import { IoMdStopwatch } from "react-icons/io";
import { TiWeatherSunny } from "react-icons/ti";
import { BiTimer } from "react-icons/bi";
import { MdOutlineSpeed } from "react-icons/md";
import { SortLapSummary } from '../../helpers/LapSummary';
import { ImFire } from "react-icons/im";

// Import your widget components
import SectorTime from './SectorTime';
import SingleDatapoint from './SingleDatapoint';
import DualDatapoint from './DualDatapoint';
import CountdownTimer from './CountdownTimer';
import stringHelper from '../../helpers/string';
import TheoreticalLaps from "./TheoreticalLaps";
import Weather from "./Weather";
import Placeholder from "./Placeholder";
import Status from "./Status";
import Speed from "./Speed";
import Heater from "./Heater";
import Gap from "./Gap";

// TODO: Create a modal for configuring widget settings and allow each widget to define its own settings.
// TODO: Widget for sector time. Display last sector time, icon and color baased on status, +/- based on top rider(s)
// TODO: Single Column Widget. Allow selecting datapoint to show latest value from.
// TODO: 2 Stack column widget. Allow selectign two datapoints to display latest value from.

// Define a mapping from type string to component
export const WidgetComponents = {
    SectorTime: SectorTime,
    SingleDatapoint: SingleDatapoint,
    DualDatapoint: DualDatapoint,
    CountdownTimer: CountdownTimer,
    TheoreticalLaps: TheoreticalLaps,
    Weather: Weather,
    Placeholder: Placeholder,
    Status: Status,
    Speed: Speed,
    Heater: Heater,
    Gap: Gap
};

export function GetSectorTimeStyleId(racer, racers, settings) {
  return GetStyleIdFromProperty(racer, racers, (settings.Show == '1') ? `min_s${settings.Sector}` : `s${settings.Sector}`);
}

export function GetDatapointStyleId(racer, racers, datapoint) {
  return GetStyleIdFromProperty(racer, racers, datapoint);
}

export function GetStyleIdFromProperty(racer, racers, property, invertSort = false) {
  let racersSorted;
  let fastestTimeInSector;
  let currentTimeInSector;
  let diffToFastest;
  let styleId;
  if (racer) {
    racersSorted = SortLapSummary(racers, property, invertSort);
    const fastestId = (racersSorted[0].name === racer.name) ? 1 : 0;
    fastestTimeInSector = parseFloat(racersSorted[fastestId][property]);
    currentTimeInSector = parseFloat(racer[property]);
    diffToFastest = (fastestTimeInSector-currentTimeInSector).toFixed(4);
    styleId = (racersSorted[0].name == racer.name) ? 2 : (diffToFastest > 0.1 ? 1 : (diffToFastest < -0.1 ? -1 : 0));
  }
  return styleId;
}

export function GetStyleId(type, lapSummary, selectedRacer, settings) {
  //console.log('GetStyleId', selectedRacer, settings);
  const racer = lapSummary.find(racer => racer.name === selectedRacer);
  if (settings.Theme === 'Simple') return null;
  switch (type) {
    case 'Placeholder':
      return null;
    case 'Heater':
      return 3;
    case 'SectorTime':
        return GetSectorTimeStyleId(racer, lapSummary, settings);
    case 'SingleDatapoint': 
      return GetDatapointStyleId(racer, lapSummary, GetDataPointAccessor(settings.Data_Point));
    case 'DualDatapoint': 
      return null;// [GetDatapointStyleId(racer, lapSummary, GetDataPointAccessor(settings.Data_Point)), GetDatapointStyleId(racer, lapSummary, GetDataPointAccessor(settings.Data_Point_2))];
    default:
      return null;
  }
}

const allowableDataPoints = ['Best_Lap', 'Diff', 'Gap', 'Last_Lap', 'Speed', 'Position'];
export function GetDataPoint(datapoint, data) {
  let dataOut = '';
  switch (datapoint) {
    case 'Best_Lap':
      dataOut = data.racer?.best_lap;
      break;
    case 'Last_Lap':
      dataOut = data.racer?.last_lap;
      break;
    case 'Diff':
      dataOut = data.racer?.diff;
      break;
    case 'Gap':
      dataOut = data.racer?.gap;
      break;
    case 'Speed':
      dataOut = data.racer?.speed;
      break;
    case 'Position':
      dataOut = data.racer?.position;
      break;
  }
  return dataOut;
}
export function GetDataPointAccessor(datapoint, isLap = false) {
  switch (datapoint) {
    case 'Last_Lap':
      return (isLap) ? 'time' : datapoint.toLowerCase();
    case 'Best_Lap':
    case 'Diff':
    case 'Gap':
    case 'Speed':
    case 'Position':
      return datapoint.toLowerCase();
  }
  return null;
}

export const InvertSortDatapoints = ['Speed'];
export function FormatDataPoint(datapoint, data, alreadyAccessed = false) {
  let value = alreadyAccessed ? data : data[GetDataPointAccessor(datapoint)];
  switch (datapoint) {
    case 'Best_Lap':
    case 'Last_Lap':
      return stringHelper.formatTime(stringHelper.safeParseFloat(value));
    case 'Diff':
    case 'Gap':
    case 'Position':
      return stringHelper.safeParseFloat(value);
    case 'Speed':
      const val = stringHelper.safeParseFloat(value);
      return (typeof val != "string") ? val.toFixed(2) : val;
    default:
      return value;
  }
}

// Copy paste the below two ojects for use with AI.
export const WidgetSchema = {
  WidgetBase: {
    Racer: {
      default: null,
      allowable: []
    }, // Special case for the selected racer handled by WidgetSettings
    Size: {
      default: '2',
      allowable: [{name: 'Extra Small', value: 1}, {name: 'Small', value: 2}, {name: 'Large', value: 4}, {name: 'Half Width', value: 6}, {name: 'Full Width', value: 12}]
    },
    Theme: {
      default: 'Highlight',
      allowable: ['Highlight', 'Simple']
    }
  },
  SectorTime: {
    Sector: {
      default: '1',
      allowable: ['1', '2', '3', '4', '5']
    },
    Show: {
      default: '1',
      allowable: [{name: 'Fastest Sector Time in Session', value: 1}, {name: 'Latest Sector Time', value: 2}]
    }
  },
  SingleDatapoint: {
    Data_Point: {
      default: 'Last_Lap',
      allowable: allowableDataPoints
    },
    Show_Difference: {
      default: 'No',
      allowable: ['Yes', 'No']
    }
  },
  DualDatapoint: {
    Data_Point_Top: {
      default: 'Speed',
      allowable: allowableDataPoints
    },
    Data_Point_Bottom: {
      default: 'Best_Lap',
      allowable: allowableDataPoints
    },
    Value_Modifier: {
      default: '1',
      allowable: [{name: 'Exact', value: 1}, {name: 'Average', value: 2}]
    }
  },
  CountdownTimer: {

  },
  Status: {

  },
  Heater: {
    Show_Icon_On_Slow_Laps: {
      default: '0',
      allowable: [{name: 'Yes', value: '1'}, {name: 'No', value: '0'}]
    },
  },
  Speed: {
    Show_Session_Top_Speed: {
      default: '0',
      allowable: [{name: 'Yes', value: '1'}, {name: 'No', value: '0'}]
    },
    Show_Session_Average_Speed: {
      default: '1',
      allowable: [{name: 'Yes', value: '1'}, {name: 'No', value: '0'}]
    },
    Show_Rider_Average_Speed_Diff: {
      default: '1',
      allowable: [{name: 'Yes', value: '1'}, {name: 'No', value: '0'}]
    },
    Discard_Speeds_Slower_Than: {
      default: '6',
      allowable: [
        {name: '1 mph', value: 1}, 
        {name: '2 mph', value: 2}, 
        {name: '3 mph', value: 3}, 
        {name: '4 mph', value: 4}, 
        {name: '5 mph', value: 5}, 
        {name: '6 mph', value: 6}, 
        {name: '7 mph', value: 7}, 
        {name: '8 mph', value: 8}, 
        {name: '9 mph', value: 9}, 
        {name: '10 mph', value: 10}
      ]
    }
  },
  TheoreticalLaps: {
    Show_Ideal_Lap_For: {
      default: 'Session',
      allowable: ['Session', 'Rider', 'Both']
    }
  },
  Weather: {

  },
  Gap: {

  }
};

export const WidgetDetails = [
    {
      id: 1,
      name: 'Sector Time',
      widgetName: 'SectorTime',
      description: 'Display details around a specific sector.',
      color: 'bg-red-500',
      icon: IoMdStopwatch,
    },
    {
      id: 2,
      name: 'Single Datapoint',
      widgetName: 'SingleDatapoint',
      description: 'Select a single datapoint to show the current value for.',
      color: 'bg-green-500',
      icon: MdFilter1,
    },
    {
      id: 3,
      name: 'Dual Datapoint',
      widgetName: 'DualDatapoint',
      description: 'Select a two datapoints to show the current value for.',
      color: 'bg-blue-500',
      icon: MdFilter2,
    },
    {
      id: 4,
      name: 'Countdown Timer',
      widgetName: 'CountdownTimer',
      description: 'Create a timer counting down seconds until a time.',
      color: 'bg-sky-500',
      icon: IoMdStopwatch,
    },
    {
      id: 5,
      name: 'Ideal Lap',
      widgetName: 'TheoreticalLaps',
      description: 'Display the theoretical best lap for session, rider and actual.',
      color: 'bg-yellow-500',
      icon: BiTimer,
    },
    {
      id: 6,
      name: 'Weather',
      widgetName: 'Weather',
      description: 'Display the forecast for a selected location and time.',
      color: 'bg-emerald-500',
      icon: TiWeatherSunny,
    },
    {
      id: 7,
      name: 'Race Status',
      widgetName: 'Status',
      description: 'Display the state of the track including flag and penalties.',
      color: 'bg-amber-600',
      icon: GiFlyingFlag,
    },
    {
      id: 8,
      name: 'Speed',
      widgetName: 'Speed',
      description: 'Display the fastest average rider speed of the session and the selected riders speed difference.',
      color: 'bg-sky-200',
      icon: MdOutlineSpeed,
    },
    {
      id: 9,
      name: 'Heater',
      widgetName: 'Heater',
      description: 'Display the momentum rider is closing gap to first place or session fastest lap time.',
      color: 'bg-red-700',
      icon: ImFire,
    },
    {
      id: 10,
      name: 'Gap',
      widgetName: 'Gap',
      description: 'Display gap / diff updates after every sector.',
      color: 'bg-green-700',
      icon: ImFire,
    }
  ];

  export function GetWidgetSchema(widgetName) {
    if (!WidgetSchema.hasOwnProperty(widgetName)) return null;
    const baseSchema = WidgetSchema['WidgetBase'];
    const widgetSchema = WidgetSchema[widgetName];
    for (const key in baseSchema) {
      widgetSchema[key] = widgetSchema.hasOwnProperty(key) ? widgetSchema[key] : baseSchema[key];
    }
    return widgetSchema;
  }