import React, { useContext, useState, useEffect } from 'react';
import { MdOutlineAddCircle } from "react-icons/md";
import { TiArrowSortedDown, TiArrowSortedUp, TiEquals } from "react-icons/ti";
import { FormatDataPoint, GetDataPointAccessor, InvertSortDatapoints } from './index';
import { ImSpinner2 } from "react-icons/im";
import StringHelper  from '../../helpers/string';
import { AppContext } from '../../context/AppContext';
import Overlay from '../Overlay';
import Table from '../Table';
import { TabList, Tab } from '../Tabs';
import SelectInput from '../SelectInput';
import { SortLapSummary } from '../../helpers/LapSummary';

function SingleDatapoint({ id, settings = {}, styleId = null, racer }) {
  const { lapSummary, laps } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLapsRacer, setSelectedLapsRacer] = useState(() => racer?.name);
  
  useEffect(() => {
    if (racer) setSelectedLapsRacer(racer?.name);
  }, [isOpen]);

  if (!settings.hasOwnProperty('Data_Point')) return (<ImSpinner2 className="animate-spin text-5xl" />);
  
  let highlightRowRules = [];
  let hideRowRules = [];
  let tableCols = [
    { Accessor: "position" },
    { Accessor: "name", Mutate: (value) => StringHelper.abbreviateName(value) },
    { Accessor: GetDataPointAccessor(settings.Data_Point), Header: `${settings.Data_Point.replace(/_/g, ' ')}`, Mutate: (value) => FormatDataPoint(settings.Data_Point, value, true) }
  ];
  let lapHighlightRowRules = [];
  let lapTableCols = [
    { Accessor: "lapindex", Header: "Lap" },
    { Accessor: GetDataPointAccessor(settings.Data_Point, true), Header: settings.Data_Point.replace(/_/g, ' '), Mutate: (value) => FormatDataPoint(settings.Data_Point, value, true) }
  ];
  let racersSorted;
  let fastestTimeInSector = 0;
  let currentTimeInSector = 0;
  let invertSort = (InvertSortDatapoints.includes(settings.Data_Point));
  if (racer) {
    racersSorted = SortLapSummary(lapSummary, GetDataPointAccessor(settings.Data_Point), invertSort);
    const fastestId = (racersSorted[0].name === racer.name) ? 1 : 0;
    fastestTimeInSector = parseFloat(racersSorted[fastestId][GetDataPointAccessor(settings.Data_Point)]);
    currentTimeInSector = parseFloat(racer[GetDataPointAccessor(settings.Data_Point)]);
    hideRowRules = [
      { Accessor: [GetDataPointAccessor(settings.Data_Point)], Value: currentTimeInSector, Comparator: (invertSort ? "<" : ">")},
      { Accessor: [GetDataPointAccessor(settings.Data_Point)], Value: 0, Comparator: "==", parseMethod: parseFloat}
    ];
    lapHighlightRowRules = [
      { Accessor: GetDataPointAccessor(settings.Data_Point, true), Value: racer?.[GetDataPointAccessor(settings.Data_Point, true)] }
    ];
    highlightRowRules = [
      { Accessor: "name", Value: racer?.name }
    ];
  }
  return (
    (!racer) ? <ImSpinner2 className="animate-spin text-5xl" /> :
      <>
      <div className="cursor-pointer flex flex-col w-full h-full" onClick={() => setIsOpen(true)}>
        <div className="text-base">{StringHelper.abbreviateName(racer.name)}</div>
        <div className="z-20">{settings.Data_Point.replace(/_/g, ' ')}</div>
        <div className="text-3xl md:text-2xl z-20 my-3">
          <MdOutlineAddCircle className={`${(settings.Theme === 'Simple') ? `hidden ` : ``}inline-block`} /> {FormatDataPoint(settings.Data_Point, racer)}
        </div> 
        {
          (settings.Show_Difference === 'Yes') ? <div className="text-3xl md:text-2xl z-20 font-bold">{(invertSort ? currentTimeInSector-fastestTimeInSector : fastestTimeInSector-currentTimeInSector).toFixed(4)}</div>
          : null
        }
        {
          (settings.Theme === 'Simple') ? '' : 
          <div className="absolute z-10 bottom-0 right-0 pr-4 opacity-30 text-8xl lg:text-9xl -mb-4 -mr-4">
            {
              (styleId == 0) ? <TiEquals className={`text-sky-700`} /> : 
              (styleId > 0) ? <TiArrowSortedUp className={`text-green-700`} /> : 
              (styleId < 0) ? <TiArrowSortedDown className={`text-red-700`} /> : ""
            }
          </div>
        }
        
      </div>
      <Overlay open={isOpen} setOpen={setIsOpen} title={`${StringHelper.abbreviateName(racer.name)} ${settings.Data_Point.replace(/_/g, ' ')} Overview`} className="p-6">
        <TabList navClassName="sticky top-0 py-4 pb-0 w-full bg-white" contentClassName="overflow-hidden p-6 pt-3">
          <Tab name="Currently Faster"><Table data={racersSorted} columns={tableCols} highlightRowRules={highlightRowRules} hideRowRules={hideRowRules} lightBg={true} /></Tab>
          <Tab name="Last Lap"><Table data={racersSorted} columns={tableCols} highlightRowRules={highlightRowRules} lightBg={true} /></Tab>
          <Tab name="All Laps">
            <SelectInput
              label="Rider"
              options={lapSummary.map(value => ({ value: value.name }))}
              selected={selectedLapsRacer}
              onChange={(e) => setSelectedLapsRacer(e)}
            />
            <Table data={laps.filter(value => value.name === selectedLapsRacer)} columns={lapTableCols} highlightRowRules={lapHighlightRowRules} lightBg={true} />
          </Tab>
        </TabList>
      </Overlay>
      </>
  );
}

export default SingleDatapoint;