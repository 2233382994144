import React from 'react';
import { AppProvider } from './context/AppContext';
import LiveTiming from './components/LiveTiming';
import './index.css';

function App() {
  return (
    <AppProvider>
      <LiveTiming />
    </AppProvider>
  );
}

export default App;
