import React, { useState, useEffect, useContext } from 'react';
import WidgetSettings from './WidgetSettings';
import { RiSettings4Fill } from "react-icons/ri";
import { WidgetComponents, GetStyleId } from './widgets/index';
import { AppContext } from '../context/AppContext';

const WidgetBase = ({ id, group = null }) => {
  const { widgets, lapSummary, laps, selectedRacer, GetWidgetSettings } = useContext(AppContext);
  const key = (group) ? `${group}-${id}` : id;
  const [type, setType] = useState((widgets && widgets[key]?.type) ? widgets[key].type : 'Placeholder');
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [settings, setSettings] = useState(() => GetWidgetSettings(key, type, {Racer: selectedRacer}));
  const [styleId, setStyleId] = useState(null);
  const [racer, setRacer] = useState(null);
  const [Widget, setWidget] = useState(() => WidgetComponents[type]);
  
  useEffect(() => {
    setType((widgets && widgets[key]?.type) ? widgets[key].type : 'Placeholder');
    setWidget(() => WidgetComponents[type]);
    setSettings(() => GetWidgetSettings(key, type, {Racer: selectedRacer}));
  }, [widgets, type]);

  useEffect(() => {
    setSettings(() => GetWidgetSettings(key, type, {Racer: selectedRacer}));
  }, [selectedRacer, type]);

  useEffect(() => {
    let racerName = (lapSummary.find(racer => racer.name === settings.Racer)) ? settings.Racer : selectedRacer;
    setStyleId(GetStyleId(type, lapSummary, racerName, settings));
  }, [Widget, settings, lapSummary, selectedRacer]);

  useEffect(() => {
    setRacer(lapSummary.find(racer => racer.name === settings.Racer) || lapSummary.find(racer => racer.name === selectedRacer));
  }, [settings.Racer, lapSummary, selectedRacer]);

  const colSpan = (settings?.Size) ? parseInt(settings.Size) : 2;
  
  return (
    <>
    <div className={`${(styleId == null) ? `bg-gray-500 shadow-inner shadow-gray-400` : (styleId == 3 ? 'bg-lime-500 shadow-inner shadow-lime-400' : (styleId > 0 ? `bg-green-400 shadow-inner shadow-green-400` : (styleId < 0 ? `bg-red-500 shadow-inner shadow-red-400` : `bg-sky-500 shadow-inner shadow-sky-400`)))} relative shadow-gray-400 bg-opacity-80 rounded-2xl text-white p-4 flex justify-center items-center col-span-2 ${colSpan==12 ? `sm:col-span-12` : (colSpan==6 ? `sm:col-span-6` : (colSpan==4 ? `sm:col-span-4` : (colSpan==2 ? `sm:col-span-2` : `sm:col-span-1`)))}`}>
      {
        (type == 'Placeholder') ? null : <button
          className="absolute top-2 right-2 z-30"
          onClick={() => setSettingsOpen(true)}
        >
          <span className="sr-only">Widget Settings</span>
          <RiSettings4Fill className="h-10 w-10 sm:h-6 sm:w-6 opacity-60 hover:opacity-100" aria-hidden="true" />
        </button>
      }
      <Widget id={key} styleId={styleId} settings={settings} racer={racer} />
    </div>
    <WidgetSettings
      id={key}
      type={type}
      settings={settings}
      isOpen={isSettingsOpen}
      setIsOpen={setSettingsOpen}
      onSettingChange={setSettings}
    />
    </>
    );
};

export default WidgetBase;
