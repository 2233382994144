import React from 'react';

// Map of WMO codes to OpenWeatherMap icon codes
const wmoToOWMIconMap = {
    '0': '01d',  // Clear sky
    '1': '02d',  // Few clouds
    '2': '03d',  // Scattered clouds
    '3': '04d',  // Broken clouds
    '45': '11d', // Thunderstorm
    '48': '50d', // Mist
    '51': '09d', // Shower rain
    '56': '13d', // Snow
    '60': '10d', // Light rain
    '61': '10d', // Moderate rain
    '62': '10d', // Heavy intensity rain
    '63': '10d', // Very heavy rain
    '64': '10d', // Extreme rain
    '65': '13d', // Light snow
    '66': '13d', // Snow
    '67': '13d', // Heavy snow
    '68': '50d', // Fog
    '80': '09d', // Light rain showers
    '81': '09d', // Heavy rain showers
    '82': '09d', // Ragged shower rain
    '95': '11d', // Thunderstorm
    '99': '11d', // Thunderstorm with heavy rain
};

const WeatherIcon = ({ code, className }) => {
    const iconCode = wmoToOWMIconMap[code] || '01n';  // Default to clear night if no match
    const iconUrl = `https://openweathermap.org/img/wn/${iconCode}@2x.png`;

    return (
        <img src={iconUrl} alt="Weather icon" className={className} />
    );
};

export default WeatherIcon;
