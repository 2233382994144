import React, { useState, useContext, useEffect } from 'react';
import { WidgetSchema, WidgetDetails, GetWidgetSchema } from './widgets';
import Overlay from './Overlay';
import { AppContext } from '../context/AppContext';
import { FaTrash } from "react-icons/fa";
import SelectInput from './SelectInput'; // Make sure the path is correct

const WidgetSettings = ({ id, type, isOpen, setIsOpen, onSettingChange, settings }) => {
  const { removeWidget, lapSummary, selectedRacer, setWidget } = useContext(AppContext);

  const handleSettingChange = (settings, key, value) => {
    const val = (key == 'Racer') ? selectedRacer : value;
    const newSettings = { ...settings, [key]: value };
    setWidget(id, newSettings);
    if (onSettingChange) onSettingChange(newSettings);
  };

  const widgetDetails = WidgetDetails.find((widget) => widget.widgetName === type);
  const widgetSchema = GetWidgetSchema(type);
  return (!widgetDetails || !widgetSchema) ? null : (
    <Overlay open={isOpen} setOpen={setIsOpen} className="p-6" title={`${widgetDetails.name || type} Component`}>
        {Object.keys(widgetSchema).map((key) => {
          const options = (key === 'Racer') ? lapSummary.map(racer => ({ value:racer.name })) : widgetSchema[key].allowable.map(item => 
            typeof item === 'object' ? item : { value: item, name: item.replace(/_/g, ' ') }
          );
          let defaultValue = (key === 'Racer') ? settings[key] || selectedRacer : settings[key] || widgetSchema[key].default;
          if (key === 'Racer' && !lapSummary.find(racer => racer.name === defaultValue)) {  
            defaultValue = selectedRacer;
          }
          return (
            <div key={key}>
              <SelectInput
                label={key.replace(/_/g, ' ')}
                className="mt-4"
                options={options}
                selected={defaultValue}
                onChange={(selectedOption) => handleSettingChange(settings, key, selectedOption)}
              />
            </div>
          );
        })}
        <div className="mt-5 sm:mt-4 flex flex-col flex-col-reverse sm:flex-row-reverse justify-between">
            <button
            type="button"
            className="my-3 inline-flex w-full justify-center rounded-lg bg-emerald-500 py-4 sm:px-3 sm:py-2 text-lg sm:text-sm font-semibold text-neutral-100 shadow-sm hover:bg-emerald-600 sm:w-auto sm:ml-3"
            onClick={() => setIsOpen(false)}
            >
            Save
            </button>
            <button
            type="button"
            className="my-3 inline-flex items-center w-full justify-center rounded-lg bg-transparent px-5 py-4 sm:px-3 sm:py-2 text-lg sm:text-sm font-semibold text-red-500 hover:shadow-sm hover:bg-neutral-100 sm:w-auto"
            onClick={() => {setIsOpen(false); removeWidget(id)} }
            >
            <FaTrash className="inline-block mr-2" /> Remove
            </button>
        </div>
    </Overlay>
  );
};

export default WidgetSettings;
