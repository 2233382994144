import React from 'react';
import StringHelper from '../helpers/string';

const Table = ({ data, columns, highlightRowRules, hideRowRules, lightBg = false, outlineAllActiveSectors = true }) => {
  const evaluateRule = (item, rule) => {
    const itemValue = item[rule.Accessor];
    const ruleValue = rule.Value;
    if (rule.parseMethod) return rule.parseMethod(itemValue) === ruleValue;
    switch (rule.Comparator) {
      case '>':
        return itemValue > ruleValue;
      case '<':
        return itemValue < ruleValue;
      case '>=':
        return itemValue >= ruleValue;
      case '<=':
        return itemValue <= ruleValue;
      case '==':
        return itemValue === ruleValue;
      case '!=':
        return itemValue !== ruleValue;
      default:
        return false;
    }
  };

  const shouldHideRow = (item) => {
    if (!hideRowRules || hideRowRules.length === 0) return false;
    return hideRowRules.some(rule => evaluateRule(item, rule));
  };

  const shouldHighlightRow = (item) => {
    if (!highlightRowRules || highlightRowRules.length === 0) return false;
    return highlightRowRules.some(rule => item[rule.Accessor] === rule.Value);
  };

  return (
    <div>
      <div className="flow-root">
        <div className="overflow-x-scroll">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-white border-separate border-spacing-y-2">
              <thead>
                <tr>
                  {columns.map(column => (
                    <th key={column.Header?.replace(' ', '_') || column.Accessor} scope="col" className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold ${lightBg ? `text-black` : `text-white`} sm:pl-0`}>
                      {('Header' in column) ? column.Header :  StringHelper.capitalizeFirstLetter(column.Accessor)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="overflow-visible">
                {!data ? null : data.map((item, index) => {
                  if (!item || shouldHideRow(item)) return null;
                  const highlightRow = shouldHighlightRow(item);

                  return (
                    <tr key={index} className={`my-1 ${(lightBg && highlightRow) ? 'bg-sky-500/[.06]' : ``}`}>
                      {columns.map(column => (
                        <td key={column.Header?.replace(' ', '_') || column.Accessor}>
                          <div className={`whitespace-nowrap px-3 py-2 text-sm ${highlightRow ? (lightBg ? `text-blue-500 font-bold` : `text-lime-400`) : (lightBg ? `text-black` : `text-white`)} ${
                            (column.BorderComparator && item[column.BorderComparator.Accessor] === column.BorderComparator.Value && (outlineAllActiveSectors || highlightRow)) ? 'shadow-highlight rounded' : ''
                          }`}>
                            {column && typeof column.Mutate === 'function' ? 
                            ((column.Mutate(item[column.Accessor])?.length === 0 && "FallbackAccessor" in column) ? <span className={`${highlightRow ? `text-lime-400` : (lightBg ? `text-black` : `text-white`)} text-opacity-70`}>{column.Mutate(item[column.FallbackAccessor])}</span> : column.Mutate(item[column.Accessor])) : 
                            item[column.Accessor]}
                          </div>
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
