import React, { useState, useContext, useEffect } from 'react';
import { GiFlyingFlag } from "react-icons/gi";
import { ImSpinner2 } from "react-icons/im";
import { AppContext } from '../../context/AppContext';
import Overlay from '../Overlay';
import StringHelper  from '../../helpers/string';
import { FormatDataPoint } from './index';
import { FaFire, FaIcicles } from "react-icons/fa";
import Table from '../Table';
import stringHelper from '../../helpers/string';

function findMinTimeObject(array) {
  if (!array || array.length === 0) return null;
  return array.reduce((minObj, currentObj) => {
      return parseFloat(currentObj.time) < parseFloat(minObj.time) ? currentObj : minObj;
  });
}
function getSecondLowestLapIndexObject(array) {
  if (array.length < 2) {
    return null;
  }

  // Sort the array based on the lapindex property
  array.sort((a, b) => a.lapindex - b.lapindex);

  // Return the object with the second lowest lapindex
  return array[1];
}
function calculateAllTotalDifference(obj1, laps) {
  if (!obj1) return null;
  const properties = ['s1', 's2', 's3', 's4', 's5'];
  let racerDiffs = {};
  laps.forEach((lap) => {
    racerDiffs[lap.name] = { diff: calculateTotalDifference(obj1, lap, laps), predicted: parseFloat(obj1.time) + calculateTotalDifference(obj1, lap, laps), name: lap.name};
  });
  racerDiffs = Object.fromEntries(Object.entries(racerDiffs).sort((a, b) => a[1].diff - b[1].diff));
  return racerDiffs;

}
function calculateTotalDifference(obj1, obj2, laps) {
  if (!obj1 || !obj2) return null;
  const properties = ['s1', 's2', 's3', 's4', 's5'];
  let totalDifference;
  properties.forEach((prop, index) => {
      const value1 = obj1[prop] ? parseFloat(obj1[prop]) : null;
      const value2 = obj2[prop] ? parseFloat(obj2[prop]) : null;
      const lastLapLastValue1 = getSecondLowestLapIndexObject(laps.filter(lap => lap.name === obj1.name));
      const lastLapLastValue2 = getSecondLowestLapIndexObject(laps.filter(lap => lap.name === obj2.name));
      
      const current_sector = obj2.current_sector ? parseInt(obj2.current_sector) : 0;

      if (current_sector <= 1 && lastLapLastValue1 !== null && lastLapLastValue2 !== null) { 
        properties.reverse().some((subProb) => {
          const subValue1 = lastLapLastValue1[subProb] ? parseFloat(lastLapLastValue1[subProb]) : null;
          const subValue2 = lastLapLastValue2[subProb] ? parseFloat(lastLapLastValue2[subProb]) : null;
          if (subValue1 !== null && subValue2 !== null) {
            if (!totalDifference) totalDifference = 0;
            totalDifference += subValue2 - subValue1;
            return true;
          }
          return false;
        });
      }

      if (value1 !== null && value2 !== null && index+1 < current_sector ) {
          if (!totalDifference) totalDifference = 0;
          totalDifference += value2 - value1;
      } else if (current_sector === 0) {
          // Calculate total difference for the entire based on last sector that had data from last lap
          
      }
  });

  return totalDifference;
}

const heaterTableCols = [
  { Accessor: "name", Header: "Name", Mutate: (value) => stringHelper.abbreviateName(value) },
  { Accessor: "diff", Header: "Pace", Mutate: (value) => { return isNaN(value) ? <ImSpinner2 className="animate-spin text-sm" /> : ((value > 0 ? '+' : '')+value?.toFixed(1)) } },
  { Accessor: "predicted", Header: "Predicted Lap Time", Mutate: (value) => { return isNaN(value) ? <ImSpinner2 className="animate-spin text-sm" /> : FormatDataPoint('Best_Lap', value, true) } }
];

function Heater({ id, settings = {}, styleId = null, racer }) {
  const { laps } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [fastestLap, setFastestLap] = useState(() => findMinTimeObject(laps));
  const [racerDiffs, setRacerDiffs] = useState(() => calculateAllTotalDifference(fastestLap, laps));
  const [diff, setDiff] = useState(() => calculateTotalDifference(fastestLap, racer, laps));
  
  useEffect(() => {
    setFastestLap(() => findMinTimeObject(laps));
  }, [laps]);

  useEffect(() => {
    if (racer) setRacerDiffs(() => calculateAllTotalDifference(fastestLap, laps));
  }, [fastestLap, racer, laps]);

  useEffect(() => {
    if (racerDiffs) setDiff(racerDiffs[racer.name]?.diff);
  }, [racerDiffs]);

  return (
    (!racer || !fastestLap) ? <ImSpinner2 className="animate-spin text-5xl" /> :
      <>
      <div className="flex flex-col w-full h-full cursor-pointer" onClick={() => setIsOpen(true)}>
        <div className="text-base">{StringHelper.abbreviateName(racer.name)}</div>
        <div className="text-base">Current Sector: {parseInt(racer.current_sector)}</div>
        <div>{FormatDataPoint('Best_Lap', fastestLap?.time, true)}</div>
        <div className="text-8xl md:text-6xl z-20 my-3">{isNaN(diff) ? <ImSpinner2 className="animate-spin text-5xl" /> : ((diff > 0 ? '+' : '')+diff?.toFixed(1))}</div>
        <div className="text-lg md:text-md z-20 mb-3">{(isNaN(diff) || !racerDiffs?.length) ? null : <><div>Predicting</div><div>{FormatDataPoint('Best_Lap', racerDiffs[racer.name]?.predicted, true)}</div></>}</div>
        {isNaN(diff) ? '' : <div className="z-20 text-5xl">{diff < 0 ? <FaFire className="text-red-500 text-center w-full" /> : (!settings.Show_Icon_On_Slow_Laps == 0 ? '' : <FaIcicles className="text-blue-500 text-center w-full" />)}</div>}
      </div>
      <Overlay open={isOpen} setOpen={setIsOpen} title={`All Current Lap Pace`} className="p-6">
        <div className="flex flex-col w-full">
          {
            !racerDiffs ? <ImSpinner2 className="animate-spin text-5xl" /> :
            <Table lightBg={true} data={Object.values(racerDiffs)} columns={heaterTableCols} />
          }
        </div>
      </Overlay>
      </>
  );
}

export default Heater;
