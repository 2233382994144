function abbreviateName(fullName) {
  if (!fullName) return "";
    // Check if the name contains spaces
    if (!fullName.includes(' ')) {
        return fullName; // Return the original name if there are no spaces
    }

    // Split the full name into parts
    const parts = fullName.trim().split(' ');

    // Extract the first initial and add a dot
    const firstInitial = `${parts[0].charAt(0)}.`;

    // Join the rest of the parts with spaces
    const remainingParts = parts.slice(1).join(' ');

    // Create the new format: first initial + ". " + the rest of the parts
    return `${firstInitial} ${remainingParts}`;
}
function safeParseFloat(value) {
  const result = parseFloat(value);
  return isNaN(result) ? "" : result;
}
function formatTime(secondsString, secondsDecimals = 3) {
  // Parse the input string to a float
  const totalSeconds = parseFloat(secondsString);
  if (isNaN(totalSeconds)) return "";

  // Calculate minutes and remaining seconds
  const hours = Math.floor(totalSeconds / 3600);
  let remainingSeconds = totalSeconds % 3600;

  const minutes = Math.floor(remainingSeconds / 60);
  remainingSeconds = totalSeconds % 60;

  // Format the seconds with fixed three decimal places
  const formattedSeconds = remainingSeconds.toFixed(secondsDecimals).toString().padStart(remainingSeconds<10 ? 3+secondsDecimals : 0, 0);
  
  // Combine minutes and formatted seconds
  return `${hours>0 ? `${hours}:` : ``}${minutes.toString().padStart(hours>0 ? 2 : 0, 0)}:${formattedSeconds}`;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


module.exports = {
    abbreviateName,
    safeParseFloat,
    formatTime,
    capitalizeFirstLetter
};
