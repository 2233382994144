import React, { memo, useState, useEffect } from 'react';
import Overlay from '../Overlay';
import WeatherIcon from '../WeatherIcon';
const moment = require('moment');

// Existing weather descriptions mapping remains the same
const wmoWeatherDescriptions = {
  '0': 'Clear',
  '1': 'Partly cloudy',
  '2': 'Cloudy',
  '3': 'Broken clouds',
  '4': 'Overcast',
  '5': 'Mist',
  '10': 'Fog',
  '20': 'Drizzle',
  '21': 'Freezing drizzle',
  '22': 'Dense drizzle',
  '23': 'Light rain',
  '24': 'Moderate rain',
  '25': 'Heavy rain',
  '26': 'Rain showers',
  '27': 'Torrential rain',
  '28': 'Light snow',
  '29': 'Moderate snow',
  '30': 'Heavy snow',
  '31': 'Snow grains',
  '32': 'Snow mix',
  '33': 'Rain & snow',
  '34': 'Heavy mix',
  '40': 'Light showers',
  '41': 'Heavy showers',
  '42': 'Light snow showers',
  '43': 'Heavy snow showers',
  '44': 'Mixed showers',
  '45': 'Heavy mixed showers',
  '50': 'Light thunderstorm',
  '51': 'Hailstorm',
  '52': 'Heavy thunderstorm',
  '53': 'Severe hailstorm',
  '60': 'Mild duststorm',
  '61': 'Stable duststorm',
  '62': 'Growing duststorm',
  '63': 'Mild duststorm',
  '64': 'Stable duststorm',
  '65': 'Severe duststorm',
  '70': 'Patchy fog',
  '71': 'Fog',
  '72': 'Rime fog',
  '73': 'Thinning fog',
  '74': 'Rime thinning',
  '80': 'Light hail',
  '81': 'Heavy hail',
  '82': 'Light sleet',
  '83': 'Heavy sleet',
  '84': 'Snow pellets',
  '85': 'Pellets & rain',
  '86': 'Pellets & snow',
};

const Weather = memo(({id, type, settings = {}, data, setStyleId }) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [currentWeather, setCurrentWeather] = useState({});
  const [historicalWeather, setHistoricalWeather] = useState({});
  const [isHistoricalFetched, setIsHistoricalFetched] = useState(false);

  // Todo: Pull the below in from settings and data passed to widget
  let location = { latitude: 43.8054663, longitude: -87.9943079 };
  //const oneYearAgo = moment().subtract(1, 'years').format('YYYY-MM-DD');
  let datetime = ''; // oneYearAgo
  let liveRefreshInterval = 600000;

  const isLiveWeather = (() => {
    if (!datetime) return true;
    const inputDate = new Date(datetime);
    const today = new Date();
    return inputDate.toDateString() === today.toDateString();
  })();

  useEffect(() => {
    const fetchCurrentWeather = async () => {
      try {
        const response = await fetch(`/api/weather/current?latitude=${location.latitude}&longitude=${location.longitude}`);
        const data = await response.json();
        setCurrentWeather(data);
      } catch (error) {
        console.error('Failed to fetch current weather data:', error);
      }
    };

    const fetchHistoricalWeather = async () => {
      if (!isHistoricalFetched) {
        try {
          const response = await fetch(`/api/weather/historical?latitude=${location.latitude}&longitude=${location.longitude}&start=${datetime}&end=${datetime}`);
          const data = await response.json();
          setHistoricalWeather(data);
          setIsHistoricalFetched(true);
        } catch (error) {
          console.error('Failed to fetch historical weather data:', error);
        }
      }
    };

    if (isLiveWeather) {
      fetchCurrentWeather();
      const intervalId = setInterval(fetchCurrentWeather, liveRefreshInterval);
      return () => clearInterval(intervalId);
    } else {
      fetchHistoricalWeather();
    }
  }, [location, datetime, isHistoricalFetched, isLiveWeather, liveRefreshInterval]);

  const groupHourlyByDay = (weather) => {
    const grouped = {};
    weather.hourly?.time.forEach((time, index) => {
      const day = new Date(time).toDateString();
      // check if day is less than 3 day from now
      const today = new Date();
      const daysFromNow = 2;
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + daysFromNow);
      if (new Date(day) <= futureDate) {
        if (!grouped[day]) {
          grouped[day] = [];
        }
        grouped[day].push({
          time: time,
          temperature: weather.hourly.temperature_2m[index],
          rain: weather.hourly.rain[index],
          windSpeed: weather.hourly.wind_speed_10m[index],
          windDirection: weather.hourly.wind_direction_10m[index],
          temperatureUnit: weather.hourly_units.temperature_2m,
          rainUnit: weather.hourly_units.rain,
          windSpeedUnit: weather.hourly_units.wind_speed_10m,
          windDirectionUnit: weather.hourly_units.wind_direction_10m,
          weatherCode: weather.hourly.weather_code[index],
        });
      }
    });
    return grouped;
  };

  const renderWeatherRows = (weather) => {
    const groupedData = groupHourlyByDay(weather);
    return Object.entries(groupedData).map(([day, data], index) => (
      <div key={day}>
        <h4>{day}</h4>
        <div className="grid grid-flow-col auto-cols-max overflow-x-scroll ">
        {data.map((item, idx) => (
          <div key={idx} className="flex flex-1 min-w-max mx-2 flex-col justify-between items-center">
            <WeatherIcon code={item.weatherCode} />
            <div>{new Date(item.time).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true })}</div>
            <div>{item.temperature}{item.temperatureUnit}</div>
          </div>
        ))}
        </div>
      </div>
    ));
  };
  
  const hasData = (Object.keys(currentWeather).length !== 0 || Object.keys(historicalWeather).length !== 0);
  const weather_code = hasData ? (isLiveWeather ? currentWeather.current.weather_code : historicalWeather.daily.weather_code) : 0;
  const temperature = hasData ? (isLiveWeather ? currentWeather.current.temperature_2m : historicalWeather.daily.temperature_2m_max) : 0;
  const temperatureSymbol = hasData ? (isLiveWeather ? currentWeather.current_units.temperature_2m : historicalWeather.daily_units.temperature_2m_max) : "";

  return (
    <div className="w-full flex flex-col text-center pb-4">
      <div  onClick={() => setIsOverlayOpen(true)} className="cursor-pointer">
        <div><WeatherIcon code={weather_code} className="m-auto" /></div>
        <div>{temperature}{temperatureSymbol}</div>
      </div>
      <Overlay open={isOverlayOpen} setOpen={setIsOverlayOpen} className="p-6" title={isLiveWeather ? "Weather Forecast" : "Historical Weather"}>
        <div className="space-y-4">
          {isLiveWeather ? renderWeatherRows(currentWeather) : renderWeatherRows(historicalWeather)}
        </div>
      </Overlay>
    </div>
  );
});

export default Weather;
