import React, { useState, useEffect, useContext } from 'react';
import { MdOutlineAddCircle } from "react-icons/md";
import { ImSpinner2 } from "react-icons/im";
import StringHelper from '../../helpers/string';
import Overlay from '../Overlay';
import Table from '../Table';
import { AppContext } from '../../context/AppContext';

function TheoreticalLaps({ id, settings = {}, racer, styleId = null }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const { lapSummary, laps } = useContext(AppContext);

    // Calculate the fastest sector times and their corresponding racers
    const calculateFastestLap = (laps) => {
        const sectors = ['s1', 's2', 's3', 's4', 's5'];
        let fastest = null;
        for (const sector of sectors) {
            for (let i = 0; i < laps.length; i++) {
                const racer = laps[i];
                if (racer[sector] && parseFloat(racer[sector]) < (!racer[sector]?.time ? Infinity : racer[sector]?.time)) {
                    if (fastest === null || parseFloat(racer[sector]) < fastest.time) {
                        fastest = { sector: sector, time: parseFloat(racer[sector]), racer: racer.name, lap: racer.lapindex };
                    }
                }
            }
        }
        return fastest;
    };
    
    // Calculate the fastest theoretical lap by summing the fastest sector times
    const calculateTheoreticalLapParts = (sectors, sectorCount = 5) => {
        let fastestS1 = null;
        let fastestS2 = null;
        let fastestS3 = null;
        let fastestS4 = null;
        let fastestS5 = null;
        if (!sectors || !Array.isArray(sectors) || !sectors.length) return null;
        for (const sector of sectors) {
            switch (sector.sector) {
                case 's1':
                    // Set fastestS1 to sector.time if it is less than the current fastestS1
                    if (!fastestS1 || sector.time < fastestS1) fastestS1 = sector;
                    break;
                case 's2':
                    if (!fastestS2 || (sectorCount > 1 && sector.time < fastestS2.time)) fastestS2 = sector;
                    break;
                case 's3':
                    if (!fastestS3 || (sectorCount > 2 && sector.time < fastestS3.time)) fastestS3 = sector;
                    break;
                case 's4':
                    if (!fastestS4 || (sectorCount > 3 && sector.time < fastestS4.time)) fastestS4 = sector;
                    break;
                case 's5':
                    if (!fastestS5 || (sectorCount > 4 && sector.time < fastestS5.time)) fastestS5 = sector;
                    break;
                default:
                    break;
            }
        }
        return [fastestS1, fastestS2, fastestS3, fastestS4, fastestS5];
    };

    const calculateTheoreticalLap = (sectors, sectorCount = 5) => {
        const parts = calculateTheoreticalLapParts(sectors, sectorCount);
        const theoreticalLap = (!parts || !parts.length) ? Infinity : parts[0].time + (parts[1] ? parts[1].time : 0) + (parts[2] ? parts[2].time : 0) + (parts[3] ? parts[3].time : 0) + (parts[4] ? parts[4].time : 0);
        return theoreticalLap < Infinity ? theoreticalLap : null;
    };

    function groupAndSortLaps(laps) {
        // Group laps by rider name
        const grouped = [];
        laps.forEach((lap) => {
            grouped[lap.name] = grouped[lap.name] || [];
            grouped[lap.name].push(lap);
        });
    
        // Sort each group by 'lapindex' in ascending order
        for (const rider in grouped) {
            grouped[rider].sort((a, b) => a.lapindex - b.lapindex);
        }
    
        return grouped;
    }

    const calculateIndividualFastestSectors = (laps) => {
        const sectors = ['s1', 's2', 's3', 's4', 's5'];
        const fastest = [];
        for (const sector of sectors) {
            let fastestSector = null;
            for (let i = 0; i < laps.length; i++) {
                const racer = laps[i];
                if (racer[sector] && parseFloat(racer[sector]) < (!racer[sector]?.time ? Infinity : racer[sector]?.time)) {
                    if (fastestSector === null || parseFloat(racer[sector]) < fastestSector.time) {
                        fastestSector = { sector: sector, time: parseFloat(racer[sector]), racer: racer.name, lap: racer.lapindex };
                    }
                }
            }
            if (fastestSector) fastest.push(fastestSector);
        }
        return fastest;
    };

    const calculateFastestSectors = () => {
        const fastest = [];
        const lapGroups = groupAndSortLaps(laps);
        for (const rider in lapGroups) {
            const racerLaps = lapGroups[rider];
            const racerFastest = calculateIndividualFastestSectors(racerLaps);
            if (racerFastest) fastest[racerLaps[0].name] = racerFastest;
        }
        return fastest;
    };

    const mergeArraysByKey = (objects) => {
        let result = [];
        Object.values(objects).forEach(object => {
            result = result.concat(Object.values(object));
        });
        return result;
    };

    const [fastestSectors, setFastestSectors] = useState(() => calculateFastestSectors());
    const [fastestTheoreticalLap, setFastestTheoreticalLap] = useState(() => calculateTheoreticalLap(mergeArraysByKey(fastestSectors)));
    const [fastestTheoreticalRiderLap, setFastestTheoreticalRiderLap] = useState(() => calculateTheoreticalLap((!fastestSectors.length || !racer) ? [] : fastestSectors[racer.name], 4));

    useEffect(() => {
        setFastestSectors(calculateFastestSectors());
        setFastestTheoreticalLap(calculateTheoreticalLap(mergeArraysByKey(fastestSectors), 4));
        if (racer) setFastestTheoreticalRiderLap(calculateTheoreticalLap(fastestSectors[racer.name], 4));
    }, [racer, laps]);

    const tableCols = [
        { Accessor: "sector" },
        { Accessor: 'time' },
        { Accessor: "racer", Header: "Rider" },
        { Accessor: 'lap' }
      ];

    return (
        (!racer) ? <ImSpinner2 className="animate-spin text-5xl" /> :
            <>
            <div className="flex flex-col w-full h-full">
                {
                    (settings.Show_Ideal_Lap_For != 'Both' && settings.Show_Ideal_Lap_For != 'Rider') ? null : 
                        <>
                        <div className="text-base">{StringHelper.abbreviateName(racer.name)}</div>
                        <div>Ideal Rider Lap</div>
                        <div className="text-3xl md:text-2xl z-20 my-1 cursor-pointer" onClick={() => setIsOpen2(true)}>
                            <MdOutlineAddCircle className="inline-block" />&nbsp;
                            {fastestTheoreticalRiderLap ? StringHelper.formatTime(fastestTheoreticalRiderLap) : <ImSpinner2 className="ml-2 inline-block animate-spin" />}
                        </div>
                        </>
                }
                {
                    (settings.Show_Ideal_Lap_For != 'Both' && settings.Show_Ideal_Lap_For != 'Session') ? null : 
                        <>
                        <div>Session Ideal Lap</div>
                        <div className="text-3xl md:text-2xl z-20 my-1 cursor-pointer" onClick={() => setIsOpen(true)}>
                            <MdOutlineAddCircle className="inline-block" />&nbsp;
                            {fastestTheoreticalLap ? StringHelper.formatTime(fastestTheoreticalLap) : <ImSpinner2 className="ml-2 inline-block animate-spin" />}
                        </div>
                        </>
                }
            </div>
            <Overlay open={isOpen} setOpen={setIsOpen} title="Theoretical Session Lap" className="p-6">
                <div className="text-4xl font-bold text-center">{fastestTheoreticalLap ? StringHelper.formatTime(fastestTheoreticalLap) : <ImSpinner2 className="animate-spin" />}</div>
                <Table data={calculateTheoreticalLapParts(mergeArraysByKey(fastestSectors), 4)} columns={tableCols} lightBg={true} />
            </Overlay>
            <Overlay open={isOpen2} setOpen={setIsOpen2} title="Theoretical Rider Lap" className="p-6">
                <div className="text-4xl font-bold text-center">{fastestTheoreticalRiderLap ? StringHelper.formatTime(fastestTheoreticalRiderLap) : <ImSpinner2 className="animate-spin" />}</div>
                <Table data={calculateTheoreticalLapParts(fastestSectors[racer.name], 4)} columns={tableCols} lightBg={true} />
            </Overlay>
            </>
    );
}

export default TheoreticalLaps;
