import React, { useState, useContext } from 'react';
import { AppContext } from '../../context/AppContext'; 
import { MdOutlineAddCircle } from "react-icons/md";
import WidgetSelector from '../WidgetSelector';

function Placeholder({ id, setStyleId }) {
  const [showModal, setShowModal] = useState(false);
  const { setWidgetType } = useContext(AppContext);
  //setStyleId(null);

  const handleSelectWidget = (type) => {
    if (type != null) setWidgetType(id, type);
    setShowModal(false);
  };

  return (
    <>
      <button className="py-9" onClick={() => setShowModal(true)}><MdOutlineAddCircle className="inline-block text-5xl" /></button>
      {showModal && (
        <WidgetSelector widgetId={id} handleSelectWidget={handleSelectWidget} />
      )}
    </>
  );
}

export default Placeholder;