import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../context/AppContext';
import SimpleCardList from './SimpleCardList';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { delay, filter, map } from 'lodash';
import { MapToListItems, AllTemplates, GetListItem, GetTemplate, SaveTemplate, DeleteTemplate } from '../helpers/Template';
import axios from 'axios';
import { MdOutlineSettingsSuggest } from "react-icons/md";
import Overlay from './Overlay';
import { ImSpinner2 } from "react-icons/im";
import { XCircleIcon } from '@heroicons/react/20/solid'

// TODO: Need to save and restore more than just widgets, also app settings
// TODO: need ability to save widget racer as first-fith place so that template will work for any race with rider in that position
// TODO: Need the ability to save which template is currently loaded and restore it on page load
// TODO: Setup quick buttons for pinned templates so that they can be quickly selected.

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function filterConfigItems(configItems, query) {
  if (query === '') return configItems;
  return configItems.filter((racer) => {
    return racer.name.toLowerCase().includes(query.toLowerCase())
  });
}

export default function ManageTemplates({asPinnedButtons = false, setOpen = null, className = ''}) {
  const { widgets, GenerateTemplate, SerializeTemplateContext, setWidgets, currentTemplate, setCurrentTemplateName, clickedTemplate, setClickedTemplate, pinnedTemplates, setPinnedTemplates, isRevertWindowOpen, setRevertWindowOpen } = useContext(AppContext);
  const [isAddWindowOpen, setAddWindowOpen] = useState(false);
  const [configs, setConfigs] = useState(() => AllTemplates());
  const [generatedJson, setGeneratedJson] = useState(null);
  const [isAiWindowOpen, setAiWindowOpen] = useState(false);
  const [isAiLoading, setAiLoading] = useState(false);
  const [aiPrompt, setAiPrompt] = useState('');
  const [aiError, setAiError] = useState(null);

  const [query, setQuery] = useState('');
  const [configItems, setConfigItems] = useState(() => MapToListItems(configs));
  const [filteredConfigItems, setFilteredConfigItems] = useState(() => filterConfigItems(configItems, query));
  const [newConfigName, setNewConfigName] = useState('')

  /*
  Each template needs to store
    - widget settings
    - widgets and widget order
    - Page default settings such as sections available and default rider

*/
  
  useEffect(() => {
    setFilteredConfigItems(() => filterConfigItems(configItems, query));
  }, [query]);

  const saveConfig = (configName, serializedTemplateContext) => {
    if (configName === '' || configName == null) {
      console.error('No name provided to save template', configName, serializedTemplateContext);
      return;
    }
    const newConfigs = SaveTemplate(configName, serializedTemplateContext);
    setConfigs(newConfigs);
    setConfigItems(() => MapToListItems(newConfigs));
  }

  const handleSaveConfig = () => {
    saveConfig(newConfigName, SerializeTemplateContext(newConfigName));
    handleItemClick(GetListItem(newConfigName));
    setAddWindowOpen(false);
  };
  const handleSaveEmptyConfig = () => {
    saveConfig(newConfigName, {});
    handleItemClick(GetListItem(newConfigName));
    setAddWindowOpen(false);
  };

  const templateModified = (template) => {
    if (!template) return false;
    const widgetsModified = JSON.stringify(template) !== JSON.stringify(SerializeTemplateContext(template.name));
    
    return widgetsModified;
  }

  const handleItemClick = (template) => {
    setNewConfigName('');
    setClickedTemplate(template);
    if (templateModified(currentTemplate)) {
      //setOpen && setOpen(true);
      setRevertWindowOpen(true);
    } else {
      setCurrentTemplateName(template.name);
      setWidgets(template?.widgets || []);
    }
  };

  const handlePinnedButtonClick = (template) => {
    handleItemClick(GetListItem(template.name)); // Make sure to get latest version of template as edits may not flow to this part of ui.
  }

  const handleItemRename = (oldItem, item) => {
    handleItemDelete(oldItem);
    saveConfig(item.name, item);
  };

  const handleItemDelete = (item) => {
    const newConfigs = DeleteTemplate(item.name);
    setConfigs(newConfigs);
    setConfigItems(() => MapToListItems(newConfigs));
    if (currentTemplate?.name === item.name) {
      setWidgets([]);
      setCurrentTemplateName(null);
    }
  };

  const handleGenerateTemplate = async () => {
    try {
      if (!aiPrompt) {
        setAiError('Describe the data you would like to see.');
        return;
      }
      setAiLoading(true);
      var response = await GenerateTemplate(aiPrompt);
      /*const response = await axios.post('/api/generate-widget', {
        prompt: aiPrompt // "Show me whether rider is gaining or losing pace over the course of the session"
      });*/
      console.log("Generated JSON:", response);
      setGeneratedJson(response); // Optionally store the result
      setAiWindowOpen(false);
      
      setAiError(null);
      setAiLoading(false);
    } catch (error) {
      console.error("Error generating widget JSON:", error);
      setAiError("Could not generate template suing AI response. Please try again with a different prompt.");
      setAiLoading(false);
      // Display error message in UI
    }
  };
  
  useEffect(() => {
    try {
        if (!generatedJson) return;
        console.log("Generated JSON:", generatedJson);
        var template = JSON.parse(generatedJson.result).template;
        //var description = generatedJson.description;
        template.name = `${template.name} (${Math.floor(Math.random() * 9999)})`;
        const newConfigs = SaveTemplate(template.name, template);
        setConfigs(newConfigs);
        setConfigItems(() => MapToListItems(newConfigs));
        handleItemClick(template);
    } catch (error) {
        console.error("Error parsing generated JSON:", error);
    }
  }, [generatedJson]);

  const handleItemDuplicate = (item) => {
    const newConfigs = SaveTemplate(`${item.name} (${Math.floor(Math.random() * 9999)})`, item); // TODO: Clone but remove name
    setConfigs(newConfigs);
    setConfigItems(() => MapToListItems(newConfigs));
  };

  const revertDialogContinue = () => {
    console.log(currentTemplate, clickedTemplate);
    if (currentTemplate.name !== clickedTemplate.name) {
      setWidgets(clickedTemplate.widgets);
    }
    setCurrentTemplateName(clickedTemplate.name);
    setRevertWindowOpen(false);
    setOpen && setOpen(false);
  }

  const revertDialogSave = () => {
    saveConfig(currentTemplate.name, SerializeTemplateContext(currentTemplate.name));
    revertDialogContinue();
  }

  const generateItemSubtext = (item) => {
    const itemWidgets = Object.values(item.widgets ?? []);
    const selected = currentTemplate?.name === item.name;
    return <div className="flex flex-row gap-x-2">
      <div>{!itemWidgets.length ? 'Empty' : `${itemWidgets.length} Widget${(itemWidgets.length>1?'s':'')}`}</div>
      {selected ? <div className="rounded-full px-2 bg-green-500 text-white">Selected</div> : null}
    </div>;
  }

  if (asPinnedButtons == true) {
    return <span className={`${className} mb-4 isolate inline-flex rounded-xl shadow-sm bg-gray-500 shadow-inner shadow-gray-400`}>
      {pinnedTemplates.map((template, index) => {
        const isFirst = index === 0;
        const isLast = index === pinnedTemplates.length - 1;
        return <button key={template.name} type="button" onClick={() => handlePinnedButtonClick(template)} className={`p-1 focus:z-10${isFirst ? ` rounded-l-xl` : ''}${isLast ? ` rounded-r-xl` : ''}`}>
          <span  className={`${template.name === currentTemplate?.name ? `bg-blue-500 hover:bg-blue-500` : `bg-transparent text-gray-800 `} text-white relative -ml-px inline-flex items-center px-5 py-3 font-semibold ring-2 ring-inset ring-gray-500 ring-opacity-50 rounded-xl`}>{template.name}</span>
          </button>
      })}
    </span>
  }
  return <div className={className}>
      <SimpleCardList list={configItems} emptyListText="Create templates for easy access later." renameWindowTitle="Rename Template" revertConfirmText={`Changes have been made to the ${currentTemplate?.name ? `'${currentTemplate.name}'` : 'current'} template. Would you like to save?`} addWindowTitle={`${(Object.keys(configs).includes(newConfigName) && 'Update')  || 'Create'} Template`} pinnedItems={pinnedTemplates} setPinnedItems={setPinnedTemplates} itemSubtext={generateItemSubtext} onItemClick={handleItemClick} onRename={handleItemRename} onDelete={handleItemDelete} onDuplicate={handleItemDuplicate} isAddWindowOpen={isAddWindowOpen} setAddWindowOpen={setAddWindowOpen} isRevertWindowOpen={isRevertWindowOpen} setRevertWindowOpen={setRevertWindowOpen} openRevertDialogSave={revertDialogSave} openRevertDialogContinue={revertDialogContinue} pinnedItemsLocalStorageKey="pinnedTemplates">
      <Combobox
        as="div"
        value={newConfigName}
        onChange={(config) => {
          if (!config) return;
          setQuery(config.name);
          setNewConfigName(config.name);
        }}
      >
        <Label className="block text-sm font-medium leading-6 text-gray-900">Name</Label>
        <div className="relative mt-2">
          <ComboboxInput
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(e) => {setQuery(e.target.value);setNewConfigName(e.target.value)}}
            onBlur={() => setQuery('')}
            displayValue={(config) => config?.name}
            value={newConfigName}
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>

          {filteredConfigItems.length > 0 && (
            <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredConfigItems.map((config) => (
                <ComboboxOption
                  key={config.name}
                  value={config}
                  className={({ focus }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-8 pr-4',
                      focus ? 'bg-indigo-600 text-white' : 'text-gray-900',
                    )
                  }
                >
                  {({ focus, selected }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>{config.name}</span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            focus ? 'text-white' : 'text-indigo-600',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          )}
        </div>
      </Combobox>
      {Object.keys(configs).includes(newConfigName) && <div className="mt-2 text-sm text-red-600">This will overwrite the existing `<i>{newConfigName}</i>` template.</div>}
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button onClick={() => setAddWindowOpen(false)} type="button" className="text-sm font-semibold leading-6 text-gray-900">
          Cancel
        </button>
        <button
          onClick={handleSaveEmptyConfig}
          type="button"
          disabled={!newConfigName}
          title={!newConfigName ? 'Please enter a name' : ''}
          className="disabled:opacity-75 disabled:cursor-not-allowed rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
        >
          {(Object.keys(configs).includes(newConfigName) && 'Reset Template')  || 'Create Empty'}
        </button>
        <button
          onClick={handleSaveConfig}
          type="button"
          disabled={!newConfigName}
          title={!newConfigName ? 'Please enter a name' : ''}
          className="disabled:opacity-75 disabled:cursor-not-allowed rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
        >
          {(Object.keys(configs).includes(newConfigName) && 'Update')  || 'Create'}
        </button>
      </div>
      </SimpleCardList>
      <div className='text-center'>
            <button
                onClick={setAiWindowOpen}
                className="my-3 inline-flex justify-center rounded-xl bg-green-600 py-2 px-4 text-lg font-semibold text-white hover:bg-green-500"
            >
                Generate using AI <MdOutlineSettingsSuggest className="ml-2 h-6 w-6" aria-hidden="true" />
            </button>
      </div>
      <Overlay open={isAiWindowOpen} setOpen={setAiWindowOpen} className="p-6" title="Generate Template Using AI">
          {aiError && <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon aria-hidden="true" className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{aiError}</h3>
            </div>
          </div>
        </div>}
          <div className="mb-4 text-center">
              {isAiLoading ? (
                <ImSpinner2 className="my-3 mx-auto animate-spin text-5xl" />
              ) : (
                <>
                  <textarea
                      id="aiPrompt"
                      value={aiPrompt}  // Bind the text area value to the state
                      onChange={(e) => setAiPrompt(e.target.value)}  // Update state on input change
                      rows="4"
                      className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Enter prompt for AI generation..."
                  ></textarea>
                </>
              )}
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button onClick={() => setAiWindowOpen(false)} type="button" className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
            <button
                onClick={handleGenerateTemplate}
                className="my-3 inline-flex justify-center rounded-xl bg-green-600 py-2 px-4 text-lg font-semibold text-white hover:bg-green-500"
            >
                Generate <MdOutlineSettingsSuggest className="ml-2 h-6 w-6" aria-hidden="true" />
            </button>
          </div>
      </Overlay>
    </div>
}
