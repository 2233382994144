export function SortLapSummary(lapSummary, dataPointAccessor, invertSort = false) {
    if (!lapSummary) return null;
    let racersSorted = structuredClone(lapSummary);
    racersSorted = racersSorted.sort((a, b) => {
      const aValue = parseFloat(a[dataPointAccessor]);
      const bValue = parseFloat(b[dataPointAccessor]);
      if (aValue === 0 && bValue === 0) return 0; // Both are 0, maintain current order
      if (aValue === 0) return 1; // a is 0, sort a to the back
      if (bValue === 0) return -1; // b is 0, sort b to the back
      return (invertSort ? (bValue - aValue) : (aValue - bValue));
    });
    return racersSorted;
}