import React, { useState } from 'react';

// TabList manages the list of Tab components
export const TabList = ({ children, className, navClassName, contentClassName }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.name);
  return (
    <div className={className}>
      <nav className={`${navClassName} flex space-x-4`} aria-label="Tabs">
        {React.Children.map(children.flatMap(value => value ? [value] : []), (child) =>
          React.cloneElement(child, {
            isActive: child.props.name === activeTab,
            setActiveTab: setActiveTab,
          })
        )}
      </nav>
      <div className={`${contentClassName} tab-content`}>
        {React.Children.map(children.flatMap(value => value ? [value] : []), (child) =>
          child.props.name === activeTab ? child.props.children : undefined
        )}
      </div>
    </div>
  );
};

// Tab represents an individual tab with a name and content
export const Tab = ({ name, children, className, isActive, setActiveTab }) => (
  <div className={className}>
    <button
      className={`rounded-md px-3 py-2 text-sm font-medium ${isActive ? 'text-indigo-700 bg-indigo-100' : 'text-gray-500 hover:text-gray-700'}`}
      onClick={() => setActiveTab(name)}
    >
      {name}
    </button>
  </div>
);
