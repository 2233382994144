import React, { useState, useContext, useEffect } from 'react';
import { MdOutlineAddCircle } from "react-icons/md";
import { TiArrowSortedDown, TiArrowSortedUp, TiEquals } from "react-icons/ti";
import { ImSpinner2 } from "react-icons/im";
import StringHelper  from '../../helpers/string';
import { AppContext } from '../../context/AppContext';
import Overlay from '../Overlay';
import Table from '../Table';
import { TabList, Tab } from '../Tabs';
import SelectInput from '../SelectInput';
import { SortLapSummary } from '../../helpers/LapSummary';

function getOrdinalNumber(n) {
  const lastDigit = n % 10;
  const lastTwoDigits = n % 100;

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    return `${n}st`;
  } else if (lastDigit === 2 && lastTwoDigits !== 12) {
    return `${n}nd`;
  } else if (lastDigit === 3 && lastTwoDigits !== 13) {
    return `${n}rd`;
  } else {
    return `${n}th`;
  }
}

function SectorTime({ id, settings = {}, styleId = null, racer }) {
  const { lapSummary, selectedRacer, laps } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [racersSorted, setRacersSorted] = useState(null);
  const [racersSortedByMin, setRacersSortedByMin] = useState(null);
  const [selectedLapsRacer, setSelectedLapsRacer] = useState(() => racer?.name);
  const [racerIndex, setRacerIndex] = useState(null);
  let fastestTimeInSector = lapSummary.reduce((acc, lap) => {
    const parsedSpeed = parseFloat(lap[(settings.Show == '1') ? `min_s${settings.Sector}` : `s${settings.Sector}`]);
    return (Number.isNaN(parsedSpeed) || parsedSpeed == 0) ? acc : Math.min(acc, parsedSpeed);
  }, Infinity);
  
  useEffect(() => {
    if (racer) setSelectedLapsRacer(racer?.name);
  }, [isOpen]);

  useEffect(() => {
      setRacersSorted(() => SortLapSummary(lapSummary, `s${settings.Sector}`));
      setRacersSortedByMin(() => SortLapSummary(lapSummary, `min_s${settings.Sector}`));
      if ((settings.Show == '1') && racersSortedByMin) { 
        setRacerIndex(() => racersSortedByMin.findIndex(r => r.name === racer?.name)+1);
      } else if (racersSorted) {
        setRacerIndex(() => racersSorted.findIndex(r => r.name === racer?.name)+1);
      }
  }, [racer, lapSummary, settings]);

  let highlightRowRules = [];
  let hideRowRules = [];
  let lastLapTableCols = [
    { Accessor: "name", Mutate: (value) => StringHelper.abbreviateName(value) },
    { Accessor: `s${settings.Sector}`, Header: `Last Sector ${settings.Sector}`, Mutate: (value) => StringHelper.safeParseFloat(value) }
  ];
  let lapHighlightRowRules = [];
  let lapTableCols = [
    { Accessor: "lapindex", Header: "Lap" },
    { Accessor: `s${settings.Sector}`, Header: `Sector ${settings.Sector}`, Mutate: (value) => StringHelper.safeParseFloat(value) }
  ];
  let fastestTableCols = [
    { Accessor: "name", Mutate: (value) => StringHelper.abbreviateName(value) },
    { Accessor: `min_s${settings.Sector}`, Header: `Fastest Sector ${settings.Sector}`, Mutate: (value) => StringHelper.safeParseFloat(value) }
  ];
  let currentTimeInSector = 0;
  if (racer) {
    currentTimeInSector = (settings.Show == '1') ? parseFloat(racer[`min_s${settings.Sector}`]) : parseFloat(racer[`s${settings.Sector}`]);
    hideRowRules = [
      { Accessor: [`s${settings.Sector}`], Value: (settings.Show == 1 ? fastestTimeInSector : currentTimeInSector), Comparator: ">"},
      { Accessor: [`s${settings.Sector}`], Value: 0, Comparator: "==", parseMethod: parseFloat}
    ];
    lapHighlightRowRules = [
      { Accessor: `s${settings.Sector}`, Value: racer?.[`min_s${settings.Sector}`] }
    ];
    highlightRowRules = [
      { Accessor: "name", Value: racer?.name }
    ];
  }
  return (
    (!racer) ? <ImSpinner2 className="animate-spin text-5xl" /> :
      <>
      <div className="flex flex-col w-full h-full cursor-pointer" onClick={() => setIsOpen(true)}>
        <div className="text-base">{StringHelper.abbreviateName(racer.name)}</div>
        {(settings.Show == '1') ? <div className="text-base">Fastest</div> : ''}
        <div className="z-20">Sector {settings.Sector}</div>
        <div className="text-3xl md:text-2xl z-20 my-3"><MdOutlineAddCircle className="inline-block" /> {isNaN(currentTimeInSector) ? <ImSpinner2 className="inline-block animate-spin text-2xl" /> : currentTimeInSector}</div>
        {isNaN(currentTimeInSector) ? '' : <>
          <div className="text-3xl md:text-2xl z-20 font-bold">{racerIndex == 1 ? '+' : ''}{(racerIndex == 1 ? parseFloat(racersSortedByMin[1][`min_s${settings.Sector}`])-fastestTimeInSector : fastestTimeInSector-currentTimeInSector).toFixed(3)}</div>
          <div className="absolute z-10 bottom-5 right-5 sm:bottom-0 sm:right-0 pr-4 opacity-30 text-9xl -mb-4 -mr-4">
            {
              (styleId == 0) ? <TiEquals className={`text-sky-700`} /> : 
              (styleId > 0) ? <TiArrowSortedUp className={`text-green-700`} /> : 
              (styleId < 0) ? <TiArrowSortedDown className={`text-red-700`} /> : ""
            }
          </div>
          <div className="absolute z-20 bottom-8 right-8 sm:bottom-5 sm:right-5 pr-2 text-2xl lg:text-2xl -mb-4 -mr-4">
            {getOrdinalNumber(racerIndex)}
          </div>
        </>}
      </div>
      <Overlay open={isOpen} setOpen={setIsOpen} title={`Sector ${settings.Sector} Overview`} className="p-6">
        <TabList navClassName="sticky top-0 py-4 pb-0 w-full bg-white" contentClassName="overflow-hidden p-6 pt-3">
          <Tab name="Fastest"><Table data={racersSortedByMin} columns={fastestTableCols} highlightRowRules={highlightRowRules} lightBg={true} /></Tab>
          <Tab name="Last Lap"><Table data={racersSorted} columns={lastLapTableCols} highlightRowRules={highlightRowRules} lightBg={true} /></Tab>
          <Tab name="All Laps">
            <SelectInput
              label="Rider"
              options={lapSummary.map(value => ({ value: value.name }))}
              selected={selectedLapsRacer}
              onChange={(e) => setSelectedLapsRacer(e)}
            />
            <Table data={laps.filter(value => value.name === selectedLapsRacer)} columns={lapTableCols} highlightRowRules={lapHighlightRowRules} lightBg={true} />
          </Tab>
        </TabList>
      </Overlay>
      </>
  );
}

export default SectorTime;
